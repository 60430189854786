/* eslint-disable no-nested-ternary */
/* eslint-disable react/jsx-no-bind */
// import PropTypes from 'prop-types';
import PropTypes from 'prop-types';
import { useState, useEffect, Suspense } from 'react';
import { FiMenu } from 'react-icons/fi';
import { useSelector } from 'react-redux';
import { SlLocationPin } from 'react-icons/sl';
import { RiSearchLine } from 'react-icons/ri';
import Link from '@/components/atoms/Link';
import { Button, MaxContainer, Text } from '@/components/atoms';
import BrandImage from '@/components/atoms/BrandImage';
import { LoginLogos, NavBar, CPModal } from '@/components/molecules';
import SearchBar from '@/components/molecules/SearchBar';
import { SideMenu } from '@/components/organisms';
import useAutoLogin from '@/hooks/useAutoLogin';
import useDeviceType from '@/hooks/useDeviceType';
import styles from './header.module.scss';
import { getCookies } from '@/services/cookies';

const slogans = {
  runningva: 'Tienda de running',
  futbolva: 'Tienda de futbol',
  urbanva: 'Tienda de moda',
  trainingva: 'Tienda de fitness',
  outdoorva: 'Tienda de montaña',
  kidsva: 'Tienda para niños'
};
const logoBrandsWidthSlider = {
  runningva: '180.39px',
  futbolva: '157.6px',
  trainingva: '184.86px',
  outdoorva: '192.77px',
  kidsva: '112.5px',
  urbanva: '149.4px',
  vaypol: '125.45px',
  '+sports': '141.89px'
};

const Header = ({
  withoutNavbar = false,
  withoutBrandsSlider = false,
  withoutSearchbar = false,
  withoutCP = false
}) => {
  const { cpcode } = useSelector((state) => state.ssrInfo);
  const autoLogin = useAutoLogin();
  const { isDesktop } = useDeviceType();
  const [open, setOpen] = useState(false);
  const [showSearchbar, setShowSearchbar] = useState(false);
  const [mounted, setMounted] = useState(false);
  const storeId = useSelector((state) => state.store);
  const reduxBrands = useSelector((state) => state.navBar?.available_brands);
  const liveInfo = useSelector((state) => state.streaming);
  const [openCPmodal, setOpenCPmodal] = useState({ open: false });
  let availableBrand = [];
  if (reduxBrands) availableBrand = [...reduxBrands, { code: '+sports' }];
  let body;
  if (typeof window !== 'undefined') {
    body = document.querySelector('#body');
  }

  const { avaliableAndreani } = getCookies();

  useEffect(() => {
    if (avaliableAndreani === 'true') {
      if (Object.keys(cpcode).length > 0) {
        setOpenCPmodal(cpcode);
      } else {
        setOpenCPmodal({ open: true });
      }
    }
  }, [cpcode]);

  useEffect(() => {
    body?.classList.remove('inactive');
  }, []);

  const closeMenu = () => {
    body?.classList.remove('inactive');
    setOpen(false);
  };
  const openMenu = () => {
    body?.classList.add('inactive');
    setMounted(true);
    setOpen(true);
  };

  useEffect(() => {
    autoLogin();
  }, []);

  return (
    <>
      <Suspense>
        {open && mounted && (
          <SideMenu
            close={closeMenu}
            open={open}
            mounted={mounted}
          />
        )}
      </Suspense>
      <div
        className={`${styles.header} ${
          storeId !== 'city' ? styles.desktopPadding : ''
        }`}
        style={{
          position: !isDesktop ? 'sticky' : 'initial',
          top: 0,
          paddingBlock: !isDesktop ?? '.5rem'
        }}
      >
        <MaxContainer>
          {isDesktop && storeId !== 'city' && !withoutBrandsSlider && (
            <div className={styles.slider}>
              <div className={styles.headerBrands}>
                <div className={styles.leftFlag} />
                {availableBrand.map(({ code }) => {
                  return code !== storeId ? (
                    <Link
                      prefetch
                      absolutePath
                      key={code}
                      href={code === 'vaypol' ? '/' : `/${code}`}
                    >
                      <BrandImage
                        brand={code}
                        height={15}
                        imageWidth={logoBrandsWidthSlider[code]}
                        width={logoBrandsWidthSlider[code] || 'auto'}
                        isDark
                      />
                    </Link>
                  ) : null;
                })}
                <div className={styles.rightFlag} />
              </div>
            </div>
          )}

          <div
            className={styles.container}
            // id="header-sticky"
          >
            {!isDesktop && !withoutNavbar && (
              <div style={{ display: 'flex', gap: '1rem' }}>
                <div className={styles.button}>
                  <Button
                    id="close-sidemenu"
                    withOutPadding
                    variant="icon"
                    onClick={openMenu}
                    ariaLabel="Abrir menu lateral"
                    startIcon={
                      <FiMenu
                        aria-hidden="true"
                        fontSize={30}
                        color="white"
                      />
                    }
                    brColor="#121212"
                  />
                </div>
                <div className={styles.button}>
                  <Button
                    onClick={() => setShowSearchbar(true)}
                    withOutPadding
                    variant="icon"
                    startIcon={
                      <RiSearchLine
                        aria-hidden="true"
                        fontSize={25}
                        color="white"
                      />
                    }
                  />
                </div>
              </div>
            )}
            <div>
              <Link
                linkStyle={{
                  display: 'flex',
                  flexDirection: 'column',
                  alignItems: 'flex-start'
                }}
                href="/"
              >
                <div className={styles.logo}>
                  <BrandImage
                    primary
                    color="var(--color-primary)"
                    height={isDesktop ? 48 : 35}
                    maxWidth={isDesktop ? '220px' : '140px'}
                  />
                </div>
                {!['vaypol', 'city'].includes(storeId) && isDesktop && (
                  <Text
                    textColor="white"
                    textSize="xxs"
                  >
                    {slogans[storeId]} <strong>de Vaypol</strong>
                  </Text>
                )}
              </Link>
            </div>
            {/* TODO: Agregar  isGalleryPage={isGalleryPage} para no recargar la página */}
            {isDesktop && !withoutSearchbar && <SearchBar />}
            <LoginLogos />
          </div>
        </MaxContainer>
        {showSearchbar && <SearchBar />}
        {avaliableAndreani === 'true' && isDesktop && !withoutCP && (
          <MaxContainer>
            <Button
              width="none"
              startIcon={<SlLocationPin style={{ fontSize: '20px' }} />}
              textColor="white"
              label={
                <Text textStyle={{ display: 'flex', gap: '3px' }}>
                  {openCPmodal.zip_code
                    ? `Estás en`
                    : 'Ingresá tu código postal'}
                  {openCPmodal.zip_code && (
                    <Text
                      variant="span"
                      textColor="var(--color-primary)"
                    >
                      {openCPmodal.province} ({openCPmodal.zip_code})
                    </Text>
                  )}
                </Text>
              }
              ariaLabel="cargar código postal"
              withOutPadding
              style={{
                height: 'auto',
                marginTop: '15px'
              }}
              onClick={() => {
                setOpenCPmodal({ ...openCPmodal, open: true });
              }}
            />
          </MaxContainer>
        )}
      </div>
      {!isDesktop && (
        <div
          style={{ position: !liveInfo.hasVideo ? 'relative' : 'initial' }}
          className={styles.secondLine}
        >
          {avaliableAndreani === 'true' && !withoutCP && (
            <Button
              width="none"
              startIcon={<SlLocationPin style={{ fontSize: '20px' }} />}
              textColor="white"
              label={
                <Text
                  textStyle={{
                    display: 'flex',
                    gap: '3px',
                    flexWrap: 'wrap',
                    textAlign: 'start'
                  }}
                  textSize="s"
                >
                  {openCPmodal.zip_code
                    ? `Estás en`
                    : 'Ingresá tu código postal'}
                  {openCPmodal.zip_code && (
                    <Text
                      textColor="var(--color-primary)"
                      textSize="s"
                    >
                      {openCPmodal.province} ({openCPmodal.zip_code})
                    </Text>
                  )}
                </Text>
              }
              ariaLabel="cargar código postal"
              withOutPadding
              style={{
                height: 'auto',
                alignItems: 'self-start'
              }}
              onClick={() => {
                setOpenCPmodal({ ...openCPmodal, open: true });
              }}
            />
          )}
        </div>
      )}
      {openCPmodal.open && !withoutCP && (
        <CPModal
          onClose={setOpenCPmodal}
          saved={openCPmodal}
        />
      )}
      {isDesktop && !withoutNavbar && (
        <MaxContainer>
          <div className={styles.navbar}>
            <NavBar />
          </div>
        </MaxContainer>
      )}
      {showSearchbar && (
        <button
          id="backdrop"
          style={{
            backgroundColor: 'black',
            opacity: 0.5,
            zIndex: 10,
            position: 'fixed',
            width: '100vw',
            height: '100vh',
            top: 0,
            border: 'none',
            padding: 0
          }}
          onClick={() => setShowSearchbar(false)}
          aria-label="Cerrar el buscador"
        />
      )}
    </>
  );
};

Header.propTypes = {
  withoutNavbar: PropTypes.bool,
  withoutBrandsSlider: PropTypes.bool,
  withoutCP: PropTypes.bool,
  withoutSearchbar: PropTypes.bool
};

export default Header;
